import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_fr } from "../i18n/fr-FR";

import { FaPlay, FaPause } from "react-icons/fa";

import logo from "../images/logo.png";
import foto from "../images/sito/foto.jpg";
import foto_card from "../images/gift-cards/foto_card.png";
import camicia1 from "../images/sito/camicia1.jpg";
import camicia2 from "../images/sito/camicia2.jpg";
import camicia3 from "../images/sito/camicia3.jpg";
import camicia4 from "../images/sito/camicia4.jpg";
import camicia5 from "../images/sito/camicia5.jpg";
import camicia6 from "../images/sito/camicia6.jpg";
import gemelli1 from "../images/sito/gemelli1.jpg";
import gemelli2 from "../images/sito/gemelli2.jpg";
import gemelli3 from "../images/sito/gemelli3.jpg";
import gemelli4 from "../images/sito/gemelli4.jpg";
import turrisi1 from "../images/sito/turrisi1.jpg";
import turrisi2 from "../images/sito/turrisi2.jpg";
import turrisi3 from "../images/sito/turrisi3.jpg";

import Seo from "../components/seo";

const IndexPage = (props) => {
  const { data, lang } = props;

  let lingua = {};

  switch (lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "fr-FR":
      lingua = lingua_fr;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "en-GB":
      lingua = lingua_en;
      break;
    default:
      lingua = lingua_it;
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [played, setPlayed] = useState(false);
  const [over, setOver] = useState(false);

  const video = useRef();

  function test() {
    if (video.current.paused) {
      video.current.play();
      setPlayed(true);
    } else {
      video.current.pause();
      setPlayed(false);
    }
  }

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />
      <section
        style={{
          background: "url(" + data.hero2.childImageSharp.fluid.src + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className="hero is-large"
      >
        <div className="hero-body container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="box" style={{ background: "rgba(23,23,23,.6)" }}>
                <h1 className="title is-size-3-mobile is-2 has-text-light">
                  Sartoria Turrisi
                </h1>
                <h2 className="subtitle is-4 has-text-light">
                  Camiceria su misura
                </h2>
                <h2 className="subtitle is-4 has-text-light">
                  Tel: +39 0831 377 637 - Cel: +39 333 113 5275
                </h2>
                <h2 className="subtitle is-5 has-text-light">
                  {lingua.contatti.testo05}
                </h2>
                <div className="buttons">
                  <Link
                    to="/tessuto00003"
                    className="ls-button button has-text-weight-bold is-primary is-medium is-radiusless is-fullwidth"
                  >
                    {lingua.sito.bottone1}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section is-medium">
        <div className="container">
          <div className="columns">
            <div
              className="column is-half is-offset-one-quarter"
              style={{ textAlign: "center" }}
            >
              <img src={logo} />
              <h2 className="subtitle is-3">{lingua.sito.titolo}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="section" style={{ background: "crimson" }}>
        <div className="container">
          <div className="columns is-6 is-vcentered">
            <div className="column is-7">
              <img
                src={foto_card}
                alt="Famiglia Turrisi"
                style={{ width: "100%" }}
              />
            </div>
            <div className="column is-5">
              <h2 className="title is-size-3-mobile is-2 has-text-light">
                Un regalo originale ed elegante?
              </h2>

              <div className="buttons">
                <Link
                  to="/gift-cards"
                  className="ls-button button has-text-weight-bold is-danger is-medium is-radiusless is-fullwidth"
                >
                  Acquista le nostre Gift Card
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section has-background-dark">
        <div className="container">
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src="https://www.youtube.com/embed/lQomlYpOGUM?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        {/** 
        <div>
          <div
            className="container"
            style={{ position: "relative" }}
            onMouseEnter={() => setOver(true)}
            onMouseLeave={() => setOver(false)}
          >
            <video
              ref={video}
              preload="auto"
              poster={"/poster.jpg"}
              style={{
                width: "100%",
                objectFit: "cover",
              }}
            >
              <source src={"/turrisi_finale.mp4"} type="video/mp4" />
            </video>
            {(over || !played) && (
              <button
                className="playpause"
                style={{
                  position: "absolute",
                  top: "calc(50% - 75px)",
                  left: "calc(50% - 75px)",
                  width: "150px",
                  height: "150px",
                  background: "transparent",
                  border: "none",
                  //borderRadius: "50%",
                  outline: "none",
                  boxShadow: "none",
                  cursor: "pointer",
                }}
                onClick={test}
              >
                {played ? (
                  <FaPause
                    className="is-hidden-touch"
                    style={{ fontSize: "80px", color: "white" }}
                  />
                ) : (
                  <FaPlay style={{ fontSize: "80px", color: "white" }} />
                )}
              </button>
            )}
          </div>
        </div>
        */}
      </div>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <img src={camicia1} />
            </div>
            <div className="column">
              <img src={camicia2} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <img src={camicia3} />
            </div>
            <div className="column">
              <img src={camicia4} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <img src={camicia5} />
            </div>
            <div className="column">
              <img src={camicia6} />
            </div>
          </div>
        </div>
      </section>

      <div className="section" style={{ background: "whitesmoke" }}>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-7">
              <img
                src={foto}
                alt="Famiglia Turrisi"
                style={{ width: "100%" }}
              />
            </div>
            <div className="column is-5">
              <div className="content">
                <p className="subtitle">{lingua.contatti.testo02}</p>
                <p className="subtitle">{lingua.contatti.testo03}</p>
                <p className="subtitle">{lingua.contatti.testo04}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section ">
        <div className="container">
          <div className="columns">
            <div className="column">
              <img src={turrisi1} />
            </div>
            <div className="column">
              <img src={turrisi2} />
            </div>
            <div className="column">
              <img src={turrisi3} />
            </div>
          </div>
        </div>
      </section>

      <section className="section pt-0">
        <div className="container">
          <div className="columns">
            <div className="column">
              <img src={gemelli1} />
            </div>
            <div className="column">
              <img src={gemelli2} />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <img src={gemelli3} />
            </div>
            <div className="column">
              <img src={gemelli4} />
            </div>
          </div>
        </div>
      </section>

      <div
        className="section is-medium has-text-light"
        style={{
          background: "url(" + data.hero.childImageSharp.fluid.src + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="column">
                <div
                  className="box"
                  style={{ background: "rgba(23,23,23,.6)" }}
                >
                  <h2 className="subtitle is-5 has-text-light">
                    {lingua.contatti.testo01}
                  </h2>
                </div>
              </div>
              <div className="column">
                <div
                  className="box"
                  style={{ background: "rgba(23,23,23,.6)" }}
                >
                  <h2 className="subtitle is-5 has-text-light">
                    {lingua.contatti.testo08} {lingua.contatti.testo09}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title has-text-dark">{lingua.contact.titolo}</h3>
                <p
                  className="content has-text-grey is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.contact.sottotitolo}
                </p>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <form
                method="post"
                action="https://formspree.io/paky.bucci@gmail.com"
              >
                <input type="hidden" name="bot-field" />
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <label
                          className="screen-reader-text"
                          htmlFor="lsNameInput"
                        >
                          {lingua.contact.nome}
                        </label>
                        <input
                          className="input is-radiusless"
                          id="lsNameInput"
                          type="text"
                          placeholder={lingua.sito.nome}
                          name="nome"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded">
                        <label
                          className="screen-reader-text"
                          htmlFor="lsEmailInput"
                        >
                          {lingua.contact.email}
                        </label>
                        <input
                          className="input is-radiusless"
                          id="lsEmailInput"
                          type="email"
                          placeholder={lingua.sito.email}
                          name="_replyto"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="screen-reader-text">
                          {lingua.contact.messaggio}
                        </label>
                        <textarea
                          className="textarea is-radiusless"
                          placeholder={lingua.sito.messaggio}
                          name="messaggio"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button className="button ls-button-slim has-text-weight-bold is-primary is-radiusless">
                          {lingua.contact.invia}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <p className="is-size-7 has-text-centered">Obblighi informativi per le erogazioni pubbliche: gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra impresa sono contenuti nel Registro nazionale degli aiuti di Stato di cui all'art. 52 della L.234/2012.</p>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(IndexPage);

export const query = graphql`
  query {
    sfondo: file(relativePath: { regex: "/sito/sfondo.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { regex: "/sito/hero.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero2: file(relativePath: { regex: "/sito/hero2.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { regex: "/sito/01.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo2: file(relativePath: { regex: "/sito/02.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo3: file(relativePath: { regex: "/sito/03.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    service3: file(relativePath: { regex: "/sito/home-01-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service1: file(relativePath: { regex: "/sito/home-02-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service2: file(relativePath: { regex: "/sito/home-03-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
